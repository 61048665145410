// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-grow-with-us-js": () => import("./../../../src/pages/grow-with-us.js" /* webpackChunkName: "component---src-pages-grow-with-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-and-policy-js": () => import("./../../../src/pages/privacy-and-policy.js" /* webpackChunkName: "component---src-pages-privacy-and-policy-js" */)
}

